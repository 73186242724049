<template>
  <div class="row mx-0 justify-content-center">
    <div class="col-12 mb-3 text-center font19" >
      Payment Details
    </div>
    <div class="col-12 mb-3 text-center font12" >
      <div class="row mx-0 justify-content-center">
        <div class="col-10 col-md-6 col-xl-5" >
          Your donations will be pledged daily with an accumulated payment taken at the end of each month via Debit Order.
        </div>
      </div>
    </div>
    <div class="col-12 mb-3 text-center font12 bold">
      <div class="row mx-0 justify-content-center">
        <div class="col-10 col-md-6 col-xl-5" >
          Please select the date on which you would like the Debit Order to be processed each month:
        </div>
      </div>
    </div>
    <div class="col-12 col-md-9 mb-3 text-center" >
      <div class="row justify-content-center align-items-center">
        <div class="col-12 col-md-5 border-end mb-3">
          <SelectAll
            v-model="debitMonth"
            id="debitMonth"
            :options="availableMonths"
            placeholder="Select Start Month"
            displayValue="name"
            color="gold"
            :autoSelect="true"
            :goUp="true"
          />
        </div>
        <div class="col-12 col-md-6 col-xl-5 mb-3 text-center" >
          <div class="row justify-content-center align-items-center">
            <div class="col-3 text-center mb-2" v-for="day in debitDayOptions" :key="day">
              <div class="select_amount select_amount_size" :class="{selected: debitDay && day.day === debitDay.day}" @click="selectDay(day)">
                {{day.description}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { DateTime } from 'luxon'

export default {
  components: {
    SelectAll: defineAsyncComponent(() => import('@/components/SelectAll.vue'))
  },
  props: {
    startDate: {
      type: Number,
      required: true
    },
    frequency: {
      type: String,
      required: true
    },
    debitOrderDay: {
      type: Number,
      required: true
    }
  },
  emits: ['update:startDate', 'update:debitOrderDay', 'update:frequency'],
  data () {
    return {
      debitMonth: null,
      debitMonthOptions: [
        {
          month: 1,
          name: 'January'
        },
        {
          month: 2,
          name: 'February'
        },
        {
          month: 3,
          name: 'March'
        },
        {
          month: 4,
          name: 'April'
        },
        {
          month: 5,
          name: 'May'
        },
        {
          month: 6,
          name: 'June'
        },
        {
          month: 7,
          name: 'July'
        },
        {
          month: 8,
          name: 'August'
        },
        {
          month: 9,
          name: 'September'
        },
        {
          month: 10,
          name: 'October'
        },
        {
          month: 11,
          name: 'November'
        },
        {
          month: 12,
          name: 'December'
        }
      ],
      debitDay: null,
      debitDayOptions: [
        {
          day: 1,
          description: '1st'
        },
        {
          day: 3,
          description: '3rd'
        },
        {
          day: 15,
          description: '15th'
        },
        {
          day: 25,
          description: '25th'
        }
      ]
    }
  },
  mounted () {
    this.setInitial()
  },
  watch: {
    debitMonth () {
      this.setStartDate()
    }
  },
  computed: {
    valueFrequency: {
      get () {
        return this.frequency
      },
      set (value) {
        this.$emit('update:frequency', value)
      }
    },
    valueDebitOrderDay: {
      get () {
        return this.debitOrderDay
      },
      set (value) {
        this.$emit('update:debitOrderDay', value)
      }
    },
    valueStartDate: {
      get () {
        return this.startDate
      },
      set (value) {
        this.$emit('update:startDate', value)
      }
    },
    availableMonths () {
      const currentMonth = DateTime.now().month
      return this.debitMonthOptions.filter(month => {
        if (month.month <= currentMonth) {
          return false
        }
        return true
      })
    }
  },
  methods: {
    selectDay (val) {
      this.debitDay = val
      this.valueDebitOrderDay = val.day
      this.setStartDate()
    },
    setStartDate () {
      if (this.debitMonth && this.debitDay) {
        const currentYear = DateTime.now().year
        const theDate = `${currentYear}-${this.debitMonth.month}-${this.debitDay.day}`
        this.valueStartDate = DateTime.fromFormat(theDate, 'yyyy-L-d').toMillis()
      }
    },
    setInitial () {
      this.valueFrequency = 'MONTHLY'
      if (this.valueStartDate) {
        const theDate = DateTime.fromMillis(this.valueStartDate)
        const theMonth = theDate.toFormat('L')
        const theDay = theDate.toFormat('d')
        const theDayOption = this.debitDayOptions.filter(day => {
          if (day.day !== theDay) {
            return false
          }
          return true
        })
        this.debitDay = theDayOption[0]
        const theMonthOption = this.debitMonthOptions.filter(month => {
          if (month.month !== theMonth) {
            return false
          }
          return true
        })
        this.debitMonth = theMonthOption[0]
      }
    }
  }
}
</script>
<style scoped>
.border-end {
  border-right: none !important;
}
.select_amount {
  color: var(--gold-color);
  font-weight: 700;
  background-color: var( --green-color-dark );
  cursor: pointer;
}
.select_amount:hover {
  background-color: var( --green-color );
}
.select_amount_size {
  border-radius: 50%;
  height: 44px;
  width: 44px;
  line-height: 44px;
  margin: auto;
}
.select_amount.selected {
  color: var( --green-color-dark );
  background-color: var(--gold-color);
}
@media (min-width: 992px) {
  .border-end {
    border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;
  }
}
</style>
